/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

      },
      finalize: function() {

        // Lang Switcher
        function addLangSwitch() {
          $('<li id="lang-switcher" class="ui-dropdown-list"><h2 class="visuallyhidden">Sprache wählen</h2><p class="ui-dropdown-list-trigger de"><span class="visuallyhidden">></span><strong>DE</strong></p><ul><li><a href="#" title="" class="en">EN</a></li></ul></li>').insertAfter('#menu-main > li:last-child');
          console.log('Executed addLangSwitch.');
        }
        addLangSwitch();

        // Show language dropdowns
        function addLangSwitchClick() {
          $('.ui-dropdown-list').on('click', function() {
            $('.ui-dropdown-list').toggleClass('active');
            console.log('Clicked LangSwitch.');
          });
        }
        addLangSwitchClick();

        // Disable Checkboxes if Audition is booked
        function disableCheckboxes() {
          if ($('body').hasClass('auditions')) {
            $("input[value~='(ausgebucht)']").attr("disabled", true);
          }
        }
        disableCheckboxes();

        // I really dont like empty <p>'s
        function removeEmptyP() {
          $('p').each(function () {
            var $this = $(this);
            if ($this.html().replace(/\s|&nbsp;/g, '').length === 0 || $this.html().replace(/\s|<strong>&nbsp;<\/strong>/g, '').length === 0) {
              $this.remove();
            }
          });
          console.log('Executed removeEmptyP.');
        }
        //removeEmptyP();

        // Disable some links
        function disableLinks() {
          $('h4.blog-post.footer-cat a, .post-meta a').on('click', function(e) {
            e.preventDefault();
          });
        }
        disableLinks();

        //Enable easy Link embedding from vimeo
        function replaceIframeSrcAttribute() {
          var anIframeOnthePage = $('iframe');
          if (anIframeOnthePage.length > 0) {
            var frame_source = $('iframe').attr('src');
            var frame_source_embed = frame_source.replace('https://vimeo.com/', 'https://player.vimeo.com/video/');
            $('iframe').attr('src', frame_source_embed);
          }
        }
        replaceIframeSrcAttribute();


        // Make Nav Sticky
        function makeNavSticky() {

          var stickyHeaderTop = $('.nav-primary').offset().top;
          if ($('body').hasClass('home')) {
            setTimeout(function(){ stickyHeaderTop = $('.nav-primary').offset().top; }, 2000);
          }

          $(window).scroll(function(){
            //console.log(stickyHeaderTop + 'und' + $(window).scrollTop());
            if( $(window).scrollTop() > stickyHeaderTop && stickyHeaderTop !== 0 ) {
              $('.nav-primary').css({
                'position': 'fixed',
                'top': '0',
                'left': '0',
                'width': '100%',
                'background-color' : 'black',
                'z-index' : '99999'
              }, 5000, function() {
                // Animation complete.
              }).addClass('sticky');

              $('#menu-main').css({
                'top': '0',
                'z-index' : '99999'
              }, 5000, function() {
                // Animation complete.
              }).addClass('container');

              //console.log('made sticky.');
            } else {
              $('.nav-primary').css({
                'position' : 'relative',
                'top': '0px',
              }).removeClass('sticky');

              $('#menu-main').css({
                'position': 'relative',
                'top': '0',
                'z-index' : '99999'
              }).removeClass('container');
              //console.log('not sticky.');
            }
          });
        }
        $(window).scroll( makeNavSticky() );

        // FB dynamic share
        function fbDynamicShare() {
          $('.fb-sharelink').click( function()
          {
            var shareurl = $(this).data('shareurl');
            window.open('https://www.facebook.com/sharer/sharer.php?u='+escape(shareurl)+'&t='+document.title, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
            return false;
          });
          console.log('Executed fbDynamicShare.');
        }
        fbDynamicShare();

        // Add img-responsive-Class to all images
        function addClassToAllImages() {
          $('.main img').addClass('img-responsive');
        }
        addClassToAllImages();

        // Set Page width
        function setPageWidth() {
          var windowWidth = $(window).width();
          $('.header-banner-home').css('max-width', windowWidth );
        }
        setPageWidth();
      }
    },
    // Home page
    'home': {
      init: function() {
        // fix nav bug
        $("html, body").animate({
          "margin-top": 0,
        }, 1000, function() {
          // Animation complete.
        });

        //home scroll to Nav
        // save scrolled pixel to var
        $(window).scroll(function() {
          //call parallax on scroll (workaround)
          jQuery(window).trigger('resize.px.parallax');
          var $pix_scrolled = $(window).scrollTop();
          //hide scroll icon after x pixel scrolled
          if ($pix_scrolled > 100) {
            $(".arrow-down").fadeOut();
          } else {
            $(".arrow-down").fadeIn();
          }
        });
        //actual scroll function
        //check, if admin-bar is present, if yes, sub 32px from offset.top
        if (!$('body').hasClass('admin-bar')) {
          $(".arrow-down").click(function() {
            $('html, body').animate({
              scrollTop: $(".header-container").offset().top
            }, 2000);
          });
        } else {
          $(".arrow-down").click(function() {
            $('html, body').animate({
              scrollTop: $(".header-container").offset().top - 32
            }, 2000);
          });
        }
        window.setTimeout(function() {
          $(window).trigger('resize.px.parallax');
        }, 100);
      },
      finalize: function() {

      }
    },

    'dozenten': {
      init: function() {
        //isotope
        var $container = $('#isotope-list .row'); //The ID for the list with all the blog posts
        $container.isotope({ //Isotope options, 'item' matches the class in the PHP
        percentPosition: true,
        layoutMode: 'fitRows'
      });

      // layout Isotope after each image loads
      $container.imagesLoaded().progress(function() {
        $container.isotope('layout');
      });

      //Add the class selected to the item that is clicked, and remove from the others
      var $optionSets = $('#filters'),
      $optionLinks = $optionSets.find('a');

      $optionLinks.click(function() {
        var $this = $(this);
        // don't proceed if already selected
        if ($this.hasClass('selected')) {
          return false;
        }
        var $optionSet = $this.parents('#filters');
        $optionSets.find('.selected').removeClass('selected');
        $this.addClass('selected');

        //When an item is clicked, sort the items.
        var selector = $(this).attr('data-filter');
        $container.isotope({
          filter: selector
        });

        return false;
      });
    },

    finalize: function() {

    }
  },
  'blog' :{
    init: function() {



    },
    finalize: function() {
      // THis toggles Posts
      function toggleBlogPosts(){
        // We hide all but the 3 first Posts
        $('.blog .part').hide();
        $('.blog .part4, .blog .part7').fadeIn().removeClass('part');
        var first_entry_to_show = $('.blog').find('.part').first();
        $('.more-posts').on('click', function(){
          $('.blog').find('.part').first().fadeIn().removeClass('part');
          if ($('.blog').find('.part').length < 1) {
            $('.more-posts').prop('disabled', true).html('KEINE POSTS GEFUNDEN.');
          }
        });

      }
      toggleBlogPosts();

    }
  },
  'kontakt': {
    init: function() {

      function initialize_gmaps() {

        var styleArray = [
          {
            featureType: 'all',
            stylers: [
              { saturation: -80 }
            ]
          },{
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
              { hue: '#00ffee' },
              { saturation: 50 }
            ]
          },{
            featureType: 'poi.business',
            elementType: 'labels',
            stylers: [
              { visibility: 'off' }
            ]
          }
        ];
        var cdsh = {lat: 53.56351, lng: 9.92118};
        var sprechwerk = {lat: 53.55597, lng: 10.02975};

        var icon = 'http://maps.google.com/mapfiles/kml/paddle/wht-circle.png';

        var mapOptions = {
          zoom: 12,
          center: cdsh,
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          styles: styleArray
        };

        var map = new google.maps.Map(document.getElementById('map'),
        mapOptions);

        var marker = new google.maps.Marker({
          position: cdsh,
          map: map,
          icon: icon
        });

        var contentString_cdsh = '<div id="content">'+
        '<div id="siteNotice">'+
        '</div>'+
        '<h2 id="firstHeading" class="firstHeading">CDSH</h2>'+
        '<div id="bodyContent">'+
        '<p class="cdsh_text">Stresemannstr. 374 (Eingang B)<br>22761 Hamburg<br>Tel.: +49 / 40 / 41924560<br>Fax: +49 / 40 / 2805 3310<span style="color: #3b3b3b;"><br></span><br><a href="mailto:info@cdsh.de">info@cdsh.de</a><a href="../">www.cdsh.de</a></p>'+
        '</div>'+
        '</div>';

        var infowindow_cdsh = new google.maps.InfoWindow({
          content: contentString_cdsh
        });

        marker.addListener('click', function() {
          infowindow_cdsh.open(map, marker);
        });

        var marker2 = new google.maps.Marker({
          position: sprechwerk,
          map: map,
          icon: icon
        });

        var contentString_sprechwerk = '<div id="content">'+
        '<div id="siteNotice">'+
        '</div>'+
        '<h2 id="firstHeading" class="firstHeading">sprechwerk</h2>'+
        '<div id="bodyContent">'+
        '<p class="cdsh_text">Hamburger Sprechwerk <br>Klaus-Groth-Str. 23 <br>20535 Hamburg <br>040-2442 3930<br><br><a href="mailto:info@sprechwerk.hamburg">info@sprechwerk.hamburg</a><a href="http://sprechwerk.hamburg/">sprechwerk.hamburg</a></p>'+
        '</div>'+
        '</div>';

        var infowindow_sprechwerk = new google.maps.InfoWindow({
          content: contentString_sprechwerk
        });

        marker2.addListener('click', function() {
          infowindow_sprechwerk.open(map, marker2);
        });

      }

      google.maps.event.addDomListener(window, 'load', initialize_gmaps);
      //map.refresh();

    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};
// Scroll-top button

$(document).on('scroll', function() {
  var footerHeight = $('.content-info').height() - 150;

  if($(window).scrollTop() + $(window).height() >= $(document).height() - footerHeight ){
    $('#top-link-block a').fadeIn();
    ////console.log('True');
  } else {
    $('#top-link-block a').fadeOut();
    ////console.log('False');
  }

});

// Tooltip only Text
function doTooltip() {
  $('.show-tooltip').hover(function() {
    // Hover over code
    var title = $(this).attr('data-cat-desc');
    if (title) {

      $(this).data('tipText', title).removeAttr('data-cat-desc');
      $('<p class="tooltip"></p>')
      .text(title)
      .appendTo('body')
      .fadeIn('slow');
    }
  }, function() {
    // Hover out code
    $(this).attr('data-cat-desc', $(this).data('tipText'));
    $('.tooltip').remove();
  }).mousemove(function(e) {
    var mousex = e.pageX + 20; //Get X coordinates
    var mousey = e.pageY + 10; //Get Y coordinates
    $('.tooltip')
    .css({
      top: mousey,
      left: mousex
    });
  });
  $('body').on('tap',function(){
    $('.tooltip').fadeOut();
  });
}
if ($('h3').hasClass('show-tooltip')) {
  doTooltip();
}


// Overlay
function doOverlay() {
  $(' .box, .blog-post, .gallery-item, .single-blog, .team-member, .image-post-link').each(function() {
    //jelly proper sizing
    var thumbWidth = $(this).find('img').width();
    var thumbHeight = $(this).find('img').height();
    var mask =$(this).find('.mask');

    mask.css('height', '' + thumbHeight + 'px');

    mask.css('width', '' + thumbWidth + 'px');
    console.log("Did masks with height" + thumbHeight + "and " + thumbWidth + " width");
  });
}

// Add mask-overlay to lightbox galleries
if ($('div').hasClass('gallery')) {
  $('.gallery-icon').find('a').prepend('<div class="mask plus-icon"></div>');
}
if ($('div').hasClass('mask')) {
  window.setTimeout(function() {
    doOverlay();
  }, 500);
}

// doOverlay on timeout
$(document).on("click", "#load-more", function() {
  window.setTimeout(function() {
    doOverlay();
  }, 500);

});
// doOverlay on mobile, wenn need a small Delay to wait for imagesLoaded
if ($(window).width() < 1025) {
  window.setTimeout(function() {
    doOverlay();
  }, 500);
}

// Page Fader
function newpage() {
  window.location = newLocation;
}

$('body').fadeIn(1000);

$('.nav-primary a').click(function(e) {
  e.preventDefault();
  if (this.getAttribute("href").charAt(0) !== "#") {
    newLocation = this.href;
    $('body').fadeOut(1000, newpage);
  } else {
    //console.log('Nav prevented.');
    return false;
  }
});

// Removes white border from iframe
$("iframe").prop("frameborder", 0);

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
if (jQuery('body').hasClass('home')) {
  window.setTimeout(function() {
    jQuery(window).trigger('resize.px.parallax');
  }, 100);
}
